import React from 'react';
import './Sponsors.css';
import PageWrapper from './components/layout/PageWrapper';
import Sponsor, { SponsorshipLevel } from './components/sponsors/Sponsor';
import { Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SponsorInfo from './SponsorInfo';
import HeadManipulator from './components/util/HeadManipulator';


function Sponsors() {

    return (

        <PageWrapper>
            <HeadManipulator pageName='Sponsors' canonicalPath='/sponsors' />
            <div className="Sponsors">
                <h1>Sponsors</h1>


                <div>
                    <h2>2024 Race Sponsors</h2>
                    <Button><Link to="/sponsor-info">Become a 2024 Race Sponsor!</Link></Button>

                    {/* <div className="sponsor-level olympic-sponsor-level">
                        <h3>Olympic Sponsors: $1,500 and up</h3>
                        <Row>
                            <Col lg={6} xs={12} className="mx-auto"><Sponsor sponsorshipLevel={SponsorshipLevel.Olympic} sponsorName="Paradise Oaks RV Resort" sponsorLink="https://www.paradiseoaksrv.com/" logoSrc="images/sponsor-logos/ParadiseOaks.jpg" logoAltText="Paradise Oaks RV Resort logo" /></Col>
                        </Row>
                    </div> */}

                    {/* <div className="sponsor-level elite-sponsor-level">
                        <h3>Elite Sponsors: $1,000 and up</h3>
                        <Row>
                            <Col lg={4} sm={6} xs={12} className="mx-auto"><Sponsor sponsorshipLevel={SponsorshipLevel.Elite} sponsorName="Redeemer Winter Haven" sponsorLink="https://www.redeemerwinterhaven.org/" logoSrc="images/sponsor-logos/RedeemerWinterHaven.png" logoAltText="Redeemer Winter Haven logo" imageBackgroundColor={true} extraImagePadding={true} /></Col>
                        </Row>
                    </div> */}

                    {/* <div className="sponsor-level champion-sponsor-level">
                        <h3>Champion Sponsors: $750 and up</h3>
                        <Row>
                            <Col xl={2} md={3} sm={4} xs={6} className="mx-auto"><Sponsor sponsorshipLevel={SponsorshipLevel.Champion} sponsorName="Little Wolfe&apos;s Pet Resort" sponsorLink="https://www.littlewolfes.com/" logoSrc="images/sponsor-logos/little-wolfes-logo.png" logoAltText="Little Wolfe&apos;s Pet Resort logo" /></Col>
                        </Row>
                    </div> */}

                    {/* <div className="sponsor-level marathon-sponsor-level">
                        <h3>Marathon Sponsors: $500 and up</h3>
                        <Row>
                            <Col xl={2} md={3} sm={4} xs={6} className="mx-auto"><Sponsor sponsorshipLevel={SponsorshipLevel.Marathon} sponsorName="Low Payment Kings/Mahalak Auto Group" sponsorLink="https://dodgeofwinterhaven.net/" logoSrc="images/sponsor-logos/whdodge-lowpaymentkings.png" logoAltText="Low Payment Kings/Mahalak Auto Group logo" backgroundColor={true} /></Col>
                            <Col xl={2} md={3} sm={4} xs={6} className="mx-auto"><Sponsor sponsorshipLevel={SponsorshipLevel.Marathon} sponsorName="In Memory of Larry Powell" logoAltText="In Memory of Larry Powell" extraPadding={true} backgroundColor={true} /></Col>
                            <Col xl={2} md={3} sm={4} xs={6} className="mx-auto"><Sponsor sponsorshipLevel={SponsorshipLevel.Marathon} sponsorName="Winter Haven Hospital" sponsorLink="https://baycare.org/locations/hospitals/winter-haven-hospital/patients-and-visitors/" logoSrc="images/sponsor-logos/WinterHavenHospital.jpeg" logoAltText="Winter Haven Hospital logo" /></Col>
                            <Col xl={2} md={3} sm={4} xs={6} className="mx-auto"><Sponsor sponsorshipLevel={SponsorshipLevel.Marathon} sponsorName="Straughn &amp; Turner, PA" sponsorLink='https://www.straughnturner.com/' logoSrc='images/sponsor-logos/straughn-and-turner-logo-400w.jpg' logoAltText="Straughn &amp; Turner logo" /></Col>
                        </Row>
                    </div> */}

                    <div className="sponsor-level sprinter-sponsor-level">
                        <h3>Sprinter Sponsors: $250 and up</h3>
                        <Row>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Wawa" sponsorLink='https://www.wawa.com/' /></Col>
                        </Row>
                    </div>
                </div>

                <div>
                    <Row className="mt-5">
                        <Col className="mx-auto sponsors-thank-you textbox">
                            <div className='text-center mt-2'>
                                <img className="optimistlogo sponsor-optimistlogo" src="images/OptimistLogo.jpg" alt="Winter Haven Optimist Club logo" />
                            </div>
                            <div className="mt-2">
                                <p>Many heart-felt thanks to our wonderful sponsors. Because of you, we are able to support more youth throughout our community. Santa&apos;s Run Fest is only one of many events that the Optimist Club of Winter Haven performs. Again............ THANK YOU!!!</p>
                                <p>If you or your company are interested in sponsoring, see the <Link to="../sponsor-info">Become a Sponsor page</Link>.</p>
                            </div>
                        </Col>
                    </Row>
                </div>

                <div>
                    <h2>2023 Race Sponsors</h2>

                    <div className="sponsor-level olympic-sponsor-level">
                        <h3>Olympic Sponsors: $1,500 and up</h3>
                        <Row>
                            <Col lg={6} xs={12} className="mx-auto"><Sponsor sponsorshipLevel={SponsorshipLevel.Olympic} sponsorName="Paradise Oaks RV Resort" sponsorLink="https://www.paradiseoaksrv.com/" logoSrc="images/sponsor-logos/ParadiseOaks.jpg" logoAltText="Paradise Oaks RV Resort logo" /></Col>
                        </Row>
                    </div>

                    <div className="sponsor-level elite-sponsor-level">
                        <h3>Elite Sponsors: $1,000 and up</h3>
                        <Row>
                            <Col lg={4} sm={6} xs={12} className="mx-auto"><Sponsor sponsorshipLevel={SponsorshipLevel.Elite} sponsorName="Redeemer Winter Haven" sponsorLink="https://www.redeemerwinterhaven.org/" logoSrc="images/sponsor-logos/RedeemerWinterHaven.png" logoAltText="Redeemer Winter Haven logo" imageBackgroundColor={true} extraImagePadding={true} /></Col>
                        </Row>
                    </div>

                    <div className="sponsor-level champion-sponsor-level">
                        <h3>Champion Sponsors: $750 and up</h3>
                        <Row>
                            <Col xl={2} md={3} sm={4} xs={6} className="mx-auto"><Sponsor sponsorshipLevel={SponsorshipLevel.Champion} sponsorName="Little Wolfe&apos;s Pet Resort" sponsorLink="https://www.littlewolfes.com/" logoSrc="images/sponsor-logos/little-wolfes-logo.png" logoAltText="Little Wolfe&apos;s Pet Resort logo" /></Col>
                        </Row>
                    </div>

                    <div className="sponsor-level marathon-sponsor-level">
                        <h3>Marathon Sponsors: $500 and up</h3>
                        <Row>
                            <Col xl={2} md={3} sm={4} xs={6} className="mx-auto"><Sponsor sponsorshipLevel={SponsorshipLevel.Marathon} sponsorName="Low Payment Kings/Mahalak Auto Group" sponsorLink="https://dodgeofwinterhaven.net/" logoSrc="images/sponsor-logos/whdodge-lowpaymentkings.png" logoAltText="Low Payment Kings/Mahalak Auto Group logo" backgroundColor={true} /></Col>
                            <Col xl={2} md={3} sm={4} xs={6} className="mx-auto"><Sponsor sponsorshipLevel={SponsorshipLevel.Marathon} sponsorName="In Memory of Larry Powell" logoAltText="In Memory of Larry Powell" extraPadding={true} backgroundColor={true} /></Col>
                            <Col xl={2} md={3} sm={4} xs={6} className="mx-auto"><Sponsor sponsorshipLevel={SponsorshipLevel.Marathon} sponsorName="Winter Haven Hospital" sponsorLink="https://baycare.org/locations/hospitals/winter-haven-hospital/patients-and-visitors/" logoSrc="images/sponsor-logos/WinterHavenHospital.jpeg" logoAltText="Winter Haven Hospital logo" /></Col>
                            <Col xl={2} md={3} sm={4} xs={6} className="mx-auto"><Sponsor sponsorshipLevel={SponsorshipLevel.Marathon} sponsorName="Straughn &amp; Turner, PA" sponsorLink='https://www.straughnturner.com/' logoSrc='images/sponsor-logos/straughn-and-turner-logo-400w.jpg' logoAltText="Straughn &amp; Turner logo" /></Col>
                        </Row>
                    </div>

                    <div className="sponsor-level sprinter-sponsor-level">
                        <h3>Sprinter Sponsors: $250 and up</h3>
                        <Row>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Donna Seymour, Financial Advisor" sponsorLink='https://ingramfinancialgroup.com/' /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Brooks Law Group" sponsorLink='https://www.brookslawgroup.com/' /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Floyd, Sammons & Spanjers, PA" sponsorLink='http://winterhavenlegal.com/' /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Red Hoagland Hyundai" sponsorLink='https://www.redhoagland.com/' /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Winter Haven Dermatology" sponsorLink='https://www.winterhavenderm.com/' /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Stephen F. Baker" /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Mr. John Crow" /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Mannix Pools and Grills" sponsorLink='https://www.mannixpools.com/' /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Lakeland Optimist Club" /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Florida Dermatology and Skin Cancer Centers" sponsorLink='https://www.fldscc.com/' /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Family Eye Care" sponsorLink='https://drhenne.com/' /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Chevrolet Center" sponsorLink='https://chevroletcenter.com/' /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Central Buick GMC, Inc." sponsorLink='https://www.centralbuickgmctruck.com/' /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Keenan's Precision Collision" sponsorLink='https://keenansprecisioncollision.com/' /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Bernadette/John Pilkey" /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Victor Smith Law Group PA" sponsorLink='https://victorsmithlaw.com/' /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="B.L. Lanier Fruit Company, Inc." /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Dwight H. Pate, DMD, PA" sponsorLink='http://www.drpatedmd.com/' /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Famous Tate Appliance &amp; Bedding Centers" sponsorLink='https://famoustate.com/' /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Robinson Enterprises, Inc." /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Moon Family Dental Care" sponsorLink='https://moonfamilydental.com/' /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Heartland Church" sponsorLink='https://heartchurch.org/' /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Howell &amp; Thornhill PA" sponsorLink='https://howellthornhill.com/' /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Anytime Fitness" sponsorLink='https://www.anytimefitness.com/' /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Bill and Donna Jackson" /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Beltone Hearing Center" sponsorLink='https://www.beltonehearingflorida.com/' /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Polk State College Foundation" sponsorLink='https://foundation.polk.edu/' /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Pediatric Associates" sponsorLink='https://pediatricassociates.com/locations/lakeland/winter-haven/contact-us/' /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Wawa" sponsorLink='https://www.wawa.com/' /></Col>
                        </Row>
                    </div>

                </div>

            </div>


        </PageWrapper>
    );
}

export default Sponsors;


import React from 'react';
import './Home.css';
import { Link } from 'react-router-dom';
import PageWrapper from './components/layout/PageWrapper';
import { Row, Col, Image, Button } from 'react-bootstrap';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import HeadManipulator from './components/util/HeadManipulator';

function Home() {

	const content =

		<PageWrapper contentClass="Home">
			<HeadManipulator pageName='Home' canonicalPath='/' />
			<Row>
				<Col>
					<h1>Santa&apos;s Run Fest</h1>
					<p>
						presented by<br />
						<a href="https://www.facebook.com/WHOptimistClub" target="_blank" rel="noreferrer">Winter Haven Optimist Club</a> <OpenInNewIcon />
					</p>

					<div>
						<Image src='./images/santas-run-fest-logo_medium.png' fluid
							alt='A cartoon image showing the title "Santa&apos;s Run Fest For Our Kids" with some happy children running beside Santa Claus with sunglasses. There are palm trees in the background.' />
					</div>

					<Row>
						<Col lg={6}>
							<h4>When</h4>
							<p>
								Saturday, December 14, 2024<br />
								10K ~ 7:00AM<br />
								5K ~ 7:15AM<br />
								Kids Sprints ~ 9:00AM
							</p>
							<div>
								<AddToCalendarButton name='Santa&apos;s Run Fest' label='Subscribe to calendar' inline={true} options={['Apple', 'Google', 'iCal', 'Outlook.com', 'Yahoo', 'MicrosoftTeams', 'Microsoft365']} subscribe='true' icsFile='https://calendar.google.com/calendar/ical/d19b34ad4c88430940fc06c8544378a6618957ad9e8175e717ee5efc98e0d546%40group.calendar.google.com/public/basic.ics' />
								<p className='calendar-info'>Includes race day activities, packet pickup opportunities, and discount registration cutoff dates.</p>
							</div>
						</Col>
						<Col lg={6}>
							<h4>Where</h4>
							<p>
								Chamber of Commerce Building<br />
								401 Ave. B NW<br />
								Winter Haven, FL 33881
							</p>
							<p>
								<Button><a href='https://maps.app.goo.gl/w2EGSt5k9oYT3ecX7' target='_blank'>Open in Google Maps <OpenInNewIcon /></a></Button>
							</p>
						</Col>
					</Row>
				</Col>
			</Row>
			<Row>
				<Col>
					<Button><Link to="registration">Registration Information</Link></Button>
					<Button><a target="_blank" href="https://www.athlinks.com/event/179127/results/Event/1068522/Results" rel="noreferrer">2023 Results <OpenInNewIcon /></a></Button>
					<Button><a target="_blank" href="https://www.athlinks.com/event/179127" rel="noreferrer">All Past Race Results <OpenInNewIcon /></a></Button>
				</Col>
			</Row>

		</PageWrapper>
		;

	return content;
}

export default Home;


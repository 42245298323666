import React from 'react';
import './Menu.css';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Container, } from 'react-bootstrap';
import './Menu.css';

function Menu() {
	return (

		<div className="Menu">
			<Navbar expand='lg' fixed='top'>
				<Container >
					<Navbar.Brand><Link to="/">Santa's Run Fest</Link></Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="me-auto" >
							<Nav.Link><Link to="/">Home</Link></Nav.Link>
							<Nav.Link><Link to="/registration">Registration</Link></Nav.Link>
							<Nav.Link><Link to="/course">Course</Link></Nav.Link>
							<Nav.Link><Link to="/awards">Awards</Link></Nav.Link>
							<Nav.Link><Link to="/sponsors">Sponsors</Link></Nav.Link>
							<Nav.Link><Link to="/sponsor-info">Become a Sponsor</Link></Nav.Link>
							<Nav.Link><Link to="/volunteer">Volunteer</Link></Nav.Link>
							<Nav.Link><Link to="/calendar">Calendar</Link></Nav.Link>
							<Nav.Link><Link to="/faq">FAQ</Link></Nav.Link>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</div>

	);
}

export default Menu;


import React from 'react';
import { Row, Col } from 'react-bootstrap';
import './Header.css';
import Menu from './Menu';


type HeaderProps = {
  headerClass?: string;
}

function Header(props: HeaderProps) {

  let headerClass = "Header banner";
  if (props.headerClass) {
    headerClass += " " + props.headerClass;
  }


  const content =
    <div className={headerClass}>
      <Row>
        <Col>
          <Menu />
        </Col>
      </Row>
    </div>
    ;

  return content;
}

export default Header;


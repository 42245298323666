import React from 'react';
import './AwardInfoBlock.css';


type AwardInfoBlockProps = {
    iconImageSrc: string;
    iconImageAltText: string;
    title: string;
    children: React.ReactNode;
}

function AwardInfoBlock(props: AwardInfoBlockProps) {

    const content =

        <div className="AwardInfoBlock display-card mb-3">
            <div className="text-center">
                <img className="icon" src={props.iconImageSrc} alt={props.iconImageAltText} />
            </div>
            <h2 className="mt-3">{props.title}</h2>
            <div>
                {props.children}
            </div>
        </div>
        ;

    return content;
}

export default AwardInfoBlock;

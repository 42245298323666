import React from 'react';
import './FAQ.css';
import PageWrapper from './components/layout/PageWrapper';
import { Accordion, Col, Row } from 'react-bootstrap';
import HeadManipulator from './components/util/HeadManipulator';

function FAQ() {

	return (

		<PageWrapper>
			<HeadManipulator pageName='FAQ' canonicalPath='/faq' />
			<div className="FAQ">

				<h1>FAQ (Frequently Asked Questions)</h1>

				<Row>
					<Col >
						<p>Answers to the most common questions. Come back as we add more answers!</p>
						<p>
							Have a question? Email us at <a href="mailto:info@mailgun.bywire.net?subject=Santa&apos;s+Run+Fest+question'" target="_blank" rel="noreferrer">info@mailgun.bywire.net</a>.
							If you have a question, others probably do too, so the answer may end up here!
						</p>
						<p><em>Click to expand each section.</em></p>
					</Col>
				</Row>


				<Accordion alwaysOpen={true}>
					<Accordion.Item eventKey='race-day'>
						<Accordion.Header>
							<h2>Race Day</h2>
						</Accordion.Header>
						<Accordion.Body>
							<h3>Where are the bathrooms?</h3>
							<p>
								Public Library and Port a Pottys location
							</p>

							<h3>When and where will the awards ceremony take place?</h3>
							<p>
								The target time for awards is 8:30 for 5k and 8:45 for 10k. However, this depends upon the calculation, validation, and entry
								of the final result times by our race partner, FitNiche.
								While we strive to be on time, we also need to make sure the results are accurate.
							</p>
							<p>Awards are presented outside the Chamber of Commerce building breezeway toward the start/finish line.</p>

						</Accordion.Body>
					</Accordion.Item>

					<Accordion.Item eventKey='kids-sprint'>
						<Accordion.Header>
							<h2>Kids Sprint</h2>
						</Accordion.Header>
						<Accordion.Body>
							<h3>What time do the Kids Sprints start?</h3>
							<p>
								Races should begin at 9:00, but there could be minor adjustments based on the finishing times of the 5k and 10k races
								and the official result calculations. There will be a PA announcement when the Kids Sprint is about to start.
							</p>

							<h3>Where do the Kids Sprints start?</h3>
							<p>TBD</p>

							<h3>What distance are the Kids Sprints?</h3>
							<p>
								The length varies by age group.
							</p>

						</Accordion.Body>
					</Accordion.Item>
				</Accordion>

			</div >

		</PageWrapper >
	);
}

export default FAQ;


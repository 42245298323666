import './Volunteer.css';
import PageWrapper from './components/layout/PageWrapper';
import { Button, Col, Row } from 'react-bootstrap';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';
import HeadManipulator from './components/util/HeadManipulator';


function Volunteer() {

	return (

		<PageWrapper>
			<HeadManipulator pageName='Volunteer' canonicalPath='/volunteer' />
			<div className="Volunteer">

				<h1>Volunteer</h1>

				<Row>
					<Col >
						<p>
							Having a successful race depends on you. We need your help! Your volunteer efforts help us make it all come together.
						</p>
						<p>
							<strong>Students</strong> can also earn <strong>credit for volunteer hours</strong>!
						</p>
						<p>
							We have many roles where you can help us out.
							<ul>
								<li><strong>Pre-Race Packet Pickup</strong> &mdash; Distribute shirts, bibs, and packets.</li>
								<ul>
									<li>Early Pickup &mdash; <em>Friday, December 13, 2024 from 4:00pm to 7:00pm.</em></li>
									<li>Race Day Pickup &mdash; <em>Saturday, December 14, 2024 from 5:30am to 7:15am.</em></li>
								</ul>
								<li><strong>Kids Sprint Helpers</strong> &mdash; Organize kids and help keep them on course.</li>
								<li><strong>Water Stops</strong> &mdash; Join the fun out at a water stop! Help keep people cool and hydrated,
									and cheer for the participants along the course.</li>
								<li><strong>Finisher Zone</strong> &mdash; Pass out water, finisher medals, and cheer as runners finish their event.</li>
								<li><strong>Course Marshals</strong> &mdash; We need a few hours of your time on race morning to help to keep all participants
									safe along the course.</li>
								<li>And many more!</li>
							</ul>
						</p>
						<p>
							Please sign up to volunteer on the race registration site.
						</p>
						<p>
							We appreciate your generous gift of time.
						</p>
						<p>
							{/* <em>Volunteer registration not yet available.</em> */}
							<Button><a target="_blank" href="https://runsignup.com/Race/Volunteer/FL/WinterHaven/SantasRunFest" rel="noreferrer">Register to Volunteer <OpenInNewIcon /></a></Button>
						</p>
						<Row>
							<Col className="col-12 col-lg-6 offset-lg-3">
								<div className="display-card">
									“Volunteers don&apos;t get paid, not because they&apos;re worthless, but because they&apos;re priceless.” &mdash;Sherry Anderson
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
			</div >

		</PageWrapper >
	);
}

export default Volunteer;


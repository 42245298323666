import React from 'react';
import './Registration.css';
import PageWrapper from './components/layout/PageWrapper';
import { Button, Col, Row, Table } from 'react-bootstrap';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';
import dayjs from 'dayjs';
import HeadManipulator from './components/util/HeadManipulator';

type RegPeriod = {
    id: string,
    title: string,
    startTime: number,
    endTime: number,
    price10k: string,
    price5k: string,
}

function Registration() {

    const currDate = Date.now();

    const regPeriods: RegPeriod[] = [
        {
            id: "0",
            title: "On or Before Aug 31",
            startTime: Date.parse("2024-06-20T00:00:00-0400"),
            endTime: Date.parse("2024-08-31T23:59:59-0400"),
            price10k: "35",
            price5k: "30",
        },
        {
            id: "1",
            title: "From Sep 1 through Oct 27",
            startTime: Date.parse("2024-09-01T00:00:00-0400"),
            endTime: Date.parse("2024-10-27T23:59:59-0400"),
            price10k: "40",
            price5k: "35",
        },
        {
            id: "2",
            title: "From Oct 28 through Dec 13",
            startTime: Date.parse("2024-10-28T00:00:00-0400"),
            endTime: Date.parse("2024-12-13T19:59:59-0500"),
            price10k: "45",
            price5k: "40",
        },
        {
            id: "3",
            title: "Day of Race (Dec 14)",
            startTime: Date.parse("2024-12-13T20:00:00-0500"),
            endTime: Date.parse("2024-12-14T07:15:00-0500"),
            price10k: "50",
            price5k: "45",
        },
    ];

    const showRegButton: boolean = (currDate > regPeriods[0].startTime) && (currDate < regPeriods[regPeriods.length - 1].endTime);

    const content =
        <PageWrapper>
            <HeadManipulator pageName='Registration' canonicalPath='/registration' />
            <div className="Registration">

                <h1>Registration</h1>

                <Row>
                    <Col  >

                        {showRegButton ?
                            <p>
                                <Button><a target="_blank" rel="noreferrer" href="https://runsignup.com/Race/FL/WinterHaven/SantasRunFest">Register Online <OpenInNewIcon /></a></Button>
                            </p>
                            :
                            <p>
                                {/* <Button><a target="_blank" rel="noreferrer" href="https://runsignup.com/Race/FL/WinterHaven/SantasRunFest">Registration Site <OpenInNewIcon /></a></Button><br /> */}
                                Registration is not currently available for 2024.
                            </p>
                        }

                        <p>Online registration from <strong>June&nbsp;28,&nbsp;2024&nbsp;at&nbsp;12:00&nbsp;am</strong> to <strong>December&nbsp;13,&nbsp;2024&nbsp;at&nbsp;8:00&nbsp;pm</strong>.</p>
                        <p>In-person registration on race day.</p>
                        <p><em>Check registration site for shirt availability.</em></p>

                    </Col>
                </Row>

                <h2>Registration Fees</h2>

                <Row>
                    <Col md={12} xxl={6}>
                        <div>
                            <Table hover bordered size='sm' className='dynamic-width' id='registration-fees-table'>
                                <thead>
                                    <tr>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>5k Fee</th>
                                        <th>10k Fee</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {regPeriods.map(period => (

                                        <tr className={getRegPeriodCssClass(dayjs().toDate().getTime(), period)}>
                                            <td>{dayjs(period.startTime).format('ddd D MMM YYYY h:mma')}</td>
                                            <td>{dayjs(period.endTime).format('ddd D MMM YYYY h:mma')}</td>
                                            <td>${period.price5k}</td>
                                            <td>${period.price10k}</td>
                                        </tr>



                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                    <Col md={6} xxl={3} className="mt-3 mx-auto">
                        <div className="display-card">
                            <h3>Students</h3>
                            <div>$30 for 10K</div>
                            <div>$25 for 5K</div>
                        </div>
                    </Col>
                    <Col md={6} xxl={3} className="mt-3 mx-auto">
                        <div className="display-card">
                            <h3>Kids Sprints<br />(12 and Under)</h3>
                            <div>$5 for all kids</div>
                            <div>Race distance varies with age group</div>
                            <br />
                        </div>
                    </Col>
                </Row>

                <h2>Divisions</h2>

                <Row>
                    <Col>
                        <h3>5k and 10k</h3>
                        <p>Five year divisions except on the top and bottom ends.</p>
                        <ul>
                            <li>Ages 9 and under</li>
                            <li>Ages 10-14</li>
                            <li>Ages 15-19</li>
                            <li>Ages 20-24</li>
                            <li>Ages 25-29</li>
                            <li>Ages 30-34</li>
                            <li>Ages 35-39</li>
                            <li>Ages 40-44</li>
                            <li>Ages 45-49</li> 
                            <li>Ages 50-54</li>
                            <li>Ages 55-59</li> 
                            <li>Ages 60-64</li>
                            <li>Ages 65-69</li> 
                            <li>Ages 70-74</li>
                            <li>Ages 75-79</li>
                            <li>Ages 80 and over</li>
                        </ul>
                    </Col>
                    <Col>
                        <h3>Kids</h3>
                        <p>Distances vary by age with a maximum distance of a city block (out and back).</p>
                        <ul>
                            <li>Ages 0-3 (with parent assistance)</li>
                            <li>Ages 4-5</li>
                            <li>Ages 6-7</li>
                            <li>Ages 8-9</li>
                            <li>Ages 10-12</li>
                        </ul>
                    </Col>
                </Row>


                <p>Any questions? Please email <a href="mailto:custer4music@yahoo.com">custer4music@yahoo.com</a></p>

            </div>
        </PageWrapper >
        ;

    return content;
}

function getRegPeriodCssClass(currDate: number, period: RegPeriod): string {
    console.log("current:", currDate);
    console.log("period:", period);
    let css = "";
    css = css.concat(currDate > period.startTime && currDate < period.endTime ? "current-period" : "");
    css = css.concat(currDate > period.endTime ? " expired-period" : "");
    console.log(css);
    return css;
}

export default Registration;


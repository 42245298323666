import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Footer from './Footer';
import Header from './Header';
import './PageWrapper.css';
import '../../index.css';

type PageWrapperProps = {
  contentClass?: string;
  headerClass?: string;
  footerClass?: string;
  children?: React.ReactNode;
}

function PageWrapper(props: PageWrapperProps) {


  let contentClass = "content";
  if (props.contentClass) {
    contentClass += " " + props.contentClass;
  }

  const content =
    <Container className="PageWrapper">
      <Row>
        <Col>
          <Header headerClass={props.headerClass} />
        </Col>
      </Row>
      <Row className='my-5'>
        <Col className='my-3'>
          <div className={contentClass}>
            <div className='outer-border'>
              <div className='inner-border'>
                {props.children}
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Footer footerClass={props.footerClass} />
        </Col>
      </Row>
    </Container>
    ;

  return content;
}

export default PageWrapper;


import React from 'react';
import { Row, Col, Navbar, Container } from 'react-bootstrap';
import './Footer.css';
import facebookLogo from "../../images/facebook.png"
import whOptimistLogo from "../../images/WHOptimistLogo.png"


type FooterProps = {
	footerClass?: string;
}

function Footer(props: FooterProps) {


	let footerClass = "Footer banner";
	if (props.footerClass) {
		footerClass += " " + props.footerClass;
	}


	const content =

		<div className={footerClass}>
			<Navbar expand='lg' fixed='bottom'>
				<Container >
					<Row>
						<Col className="col-auto">

							<a href="https://www.winterhavenoptimistclub.org/" target="_blank" rel="noreferrer">Winter Haven Optimist Club</a>

							<div>Learn more:</div>
						</Col>
						<Col className="col-auto">
							<div>
								<span title="Winter Haven Optimist Club Facebook page" className="mx-2">
									<a href="https://www.facebook.com/WHOptimistClub" target="_blank" rel="noreferrer"><img
										className="socials" src={facebookLogo} alt="Facebook icon" /></a>
								</span>
								<span title="Winter Haven Optimist Club web site" className="mx-2">
									<a href="https://www.winterhavenoptimistclub.org/" target="_blank" rel="noreferrer"><img className="socials"
										src={whOptimistLogo} alt="Winter Haven Optimist Club logo" /></a>
								</span>
							</div>
						</Col>
					</Row>
				</Container>
			</Navbar>
		</div>
		;

	return content;
}

export default Footer;


import React from 'react';
import './LevelBlock.css';


type LevelBlockProps = {
	name: string;
	amount: number;
	participants: number;
	children: React.ReactNode;
}

function LevelBlock(props: LevelBlockProps) {

	const content =

		<div className="LevelBlock display-card mt-3 pa-3">
			<h3>{props.name} Sponsor</h3>
			<ul>
				<li>Support amount: ${props.amount}</li>
				<li>Complimentary race participants: {props.participants}</li>
				{props.children}
			</ul>
			
		</div>
		;

	return content;
}

export default LevelBlock;

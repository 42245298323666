import React from 'react';
import { Col, Row } from 'react-bootstrap';
import './Awards.css';
import AwardInfoBlock from './components/awards/AwardInfoBlock';
import PageWrapper from './components/layout/PageWrapper';
import HeadManipulator from './components/util/HeadManipulator';

function Awards() {

    return (

        <PageWrapper>
            <HeadManipulator pageName='Awards' canonicalPath='/awards' />
            <div className="Awards">

                <h1>Awards</h1>

                <Row className="mt-2">
                    <Col lg={4}>
                        <AwardInfoBlock title="Award Medals" iconImageSrc="images/icon-awards.png" iconImageAltText="Line drawing of three awards podiums.">
                            <p>Overall race winners (male and female) in 5k and 10k.</p>
                            <p>Top 3 male/female in each age division.</p>
                            <p>Kids trophies for 1st and 2nd place for boys/girls in each youth division.</p>
                        </AwardInfoBlock>
                    </Col>
                    <Col lg={4}>
                        <AwardInfoBlock title="Finisher Medals" iconImageSrc="images/icon-medal.png" iconImageAltText="Line drawing of an award medal.">
                            <p>For all runners who complete the 10k or 5k.</p>
                            <p>For all kids participating in the Kids Sprint.</p>
                        </AwardInfoBlock>
                    </Col>
                    <Col lg={4}>
                        <AwardInfoBlock title="Tech Shirts" iconImageSrc="images/icon-shirt.png" iconImageAltText="Line drawing of a shirt.">
                            <p>Tech shirts for 5k and 10k runners.</p>
                            <p>Each participant in the Kids Sprint will receive a cotton shirt with the race logo.</p>
                            <p><em>Check registration site for cutoff date to receive a shirt.</em></p>
                        </AwardInfoBlock>
                    </Col>
                </Row>

                <h2>Team Competitions</h2>
                <div>
                    <p>Runners may optionally create and join teams to compete against each other in any of these team categories:</p>
                    <ul>
                        <li><strong>Families</strong></li>
                        <li><strong>Corporate</strong></li>
                        <li><strong>Social/Church/School</strong></li>
                    </ul>
                    <p>
                        There are 2 trophies in each category:
                    </p>
                    <ul>
                        <li><strong>Largest Team</strong> &mdash; Number of runners who register and complete any race event.</li>
                        <li><strong>Fastest Team</strong> &mdash; Aggregate of top 3 team runners on a team in the 5k race (minimum of 3 runners to qualify).</li>
                    </ul>
                    <p>To compete as a team:</p>
                    <ul>
                        <li>Create a team name.</li>
                        <li>Go to the registration page, and begin to register for the 5k race.</li>
                        <li>During registration, you will have an option to join a Group/Team.</li>
                        <ul>
                            <li>Search for your team to see it if already exists. If so, join the team!</li>
                            <li>If your team does not yet exist, create the team with your team name and tell your teammates!</li>
                        </ul>
                        <li>Encourage your friends to join your team. And have fun!</li>
                    </ul>
                    <p>
                        You can also see a list of existing teams on the registration site by clicking <strong>Group/Teams</strong> in the left side nav.
                        Or join a team if you forget during registration!
                    </p>
                    <p>
                        <em>Deadline for team signups is Friday, December 13, 2024 at 12:00 pm (noon).</em>
                    </p>
                    <p>
                        Questions? Email us at <a href="mailto:info@mailgun.bywire.net" target="_blank" rel="noreferrer">info@mailgun.bywire.net</a>,
                        or call/text Rena at <a href="tel:863-604-7217">863-604-7217</a>.
                    </p>
                </div>

                <Row>
                    <Col lg={4}>
                        <img className="photo" src="images/awards1.jpg" alt="People milling around an awards table waiting for race awards to be distributed." />
                    </Col>
                    <Col lg={4}>
                        <img className="photo" src="images/awards2.jpg" alt="More people waiting for award medals to be presented." />
                    </Col>
                    <Col lg={4}>
                        <img className="photo" src="images/awards3.jpg" alt="Runners awaiting the award presentation." />
                    </Col>
                </Row>



            </div>
        </PageWrapper>
    );
}

export default Awards;


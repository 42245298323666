import React from 'react';
import './Calendar.css';
import PageWrapper from './components/layout/PageWrapper';
import { Col, Row } from 'react-bootstrap';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import FullCalendar from '@fullcalendar/react'
import listPlugin from '@fullcalendar/list';
import iCalendarPlugin from '@fullcalendar/icalendar'
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import HeadManipulator from './components/util/HeadManipulator';


function Calendar() {

	const icsUrl = "https://calendar.google.com/calendar/ical/d19b34ad4c88430940fc06c8544378a6618957ad9e8175e717ee5efc98e0d546%40group.calendar.google.com/public/basic.ics";
	const icsUrlLocal = "ical/santas-run-fest.ics";

	return (

		<PageWrapper>
			<HeadManipulator pageName='Calendar' canonicalPath='/calendar' />
			<div className="Calendar">

				<h1>Calendar</h1>

				<Row>
					<Col >

						<p>Includes race day activities, packet pickup opportunities, and discount registration cutoff dates.</p>

						<h2>Subscribe</h2>
						<p>Subscribing to the calendar will add all Santa&apos;s Run Fest activities to your calendar and will keep you up-to-date
							with any changes on your own phone or computer.
						</p>
						<p>
							<AddToCalendarButton name='Santa&apos;s Run Fest' label='Subscribe to calendar' inline={true} options={['Apple', 'Google', 'iCal', 'Outlook.com', 'Yahoo', 'MicrosoftTeams', 'Microsoft365']} subscribe='true' icsFile={icsUrl} />
						</p>

						<h2>Agenda</h2>
						<p>View all race dates below.</p>

						<FullCalendar plugins={[listPlugin, iCalendarPlugin, momentTimezonePlugin]} initialView='listYear' timeZone="America/New_York" events={{ url: icsUrlLocal, format: 'ics' }}></FullCalendar>

					</Col>
				</Row>
			</div >

		</PageWrapper >
	);
}

export default Calendar;


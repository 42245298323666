import React from 'react';
import './Course.css';
import PageWrapper from './components/layout/PageWrapper';
import { Col, Image, Row } from 'react-bootstrap';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import HeadManipulator from './components/util/HeadManipulator';


import { createRoot } from 'react-dom/client';
import { APIProvider, Map } from '@vis.gl/react-google-maps';

function Course() {

	const [lbOpen, setLightboxOpen] = React.useState(false);
	const [lbIndex, setLightboxIndex] = React.useState(0);

	return (

		<PageWrapper>
			<HeadManipulator pageName='Course' canonicalPath='/course' />
			<div className="Course">
				<h1>Course</h1>

				<Row>
					<Col lg={6}>
						<p><a href="images/course/2024-santas-run-fest-map.jpg" target='_blank'><Image src='images/course/2024-santas-run-fest-map.jpg' className="map-image" /></a></p>
					</Col>
					<Col lg={6}>
						<h2>Maps</h2>
						<p>Open map in new window</p>
						<ul>
							<li><a href="images/course/2024-santas-run-fest-map.jpg" target='_blank'>Map image <OpenInNewIcon /></a></li>
							<li><a href='images/course/2024-santas-run-fest-map.pdf' target='_blank'>One-page format PDF (high-res) <OpenInNewIcon /></a></li>
							<li><a href='images/course/2024-santas-run-fest-map_long.pdf' target='_blank'>Long format PDF (high-res) <OpenInNewIcon /></a></li>
							<li><a href='https://www.mapmyrun.com/routes/view/6109976770' target='_blank' rel="noreferrer">5k route at MapMyRun.com <OpenInNewIcon /></a> - See embedded map below</li>
							<li><a href='https://www.mapmyrun.com/routes/view/6109972804' target='_blank' rel="noreferrer">10k route at MapMyRun.com <OpenInNewIcon /></a> - See embedded map below</li>
						</ul>
						<p>Interactive map <em>Coming soon!</em></p>

						<p>
							This year's course is an out-and-back, flat, and fast route along a wooded asphalt path around Lake Silver and
							beyond. It will be well marked with water and nutrition stations.
							The course will begin and end near the Chamber of Commerce building in the Central Park area.
							There will be volunteers all along the route.
						</p>

						<h2>Winter Haven</h2>
						<p>
							<a href="https://www.mywinterhaven.com/" target="_blank" rel="noreferrer">Winter Haven <OpenInNewIcon /></a> is a beautiful city of approximately 59,000 friendly folks. As
							the <a href="https://www.winterhavenchamber.com/" target="_blank" rel="noreferrer">Winter Haven Chamber of Commerce <OpenInNewIcon /></a> mentions,
							<em>"To some we're the perfect haven from the harsh winter months up North, and to
								others we are the opportunity to be involved in a town with over 55 lakes to enjoy with fantastic sunrises and sunsets over the water"</em>
							...AND an absolutely superb terrain for running.
						</p>

						<h2>Running</h2>
						<p>
							New runners are especially encouraged to join the fastest growing athletic activity in the
							world. Running encourages increased physical fitness, effective weight control, greater patience, and an
							overall sense of well-being. Besides that, runners
							seem to be among the most happy, up-beat, smiling, smart, fun-loving, good-looking, physically-fit humans on
							the planet. At the finish, everyone is exhausted, sweaty, smelly, somewhat dirty, frequently nauseous,
							but... SMILING!
						</p>
					</Col>
				</Row>
			</div>

			{/* <div>
				<iframe src="https://www.google.com/maps/d/embed?mid=127g3rMZqqUHnuAPSQkLf9SZnwfaaar0&ehbc=2E312F&noprof=1" title="course map" width="640px" height="480px"></iframe>
			</div> */}


			{/* <div>
				<APIProvider apiKey='xxx'>
					<Map
						style={{ width: '80vw', height: '80vh' }}
						defaultCenter={{ lat: 22.54992, lng: 0 }}
						defaultZoom={3}
						gestureHandling={'greedy'}
						disableDefaultUI={true}
					/>
				</APIProvider>
			</div> */}


			<Row>
				<Col xs={12} lg={6}>
					<iframe id='mapmyfitness_route' title="Santa&apos;s Run Fest 5k (2024)"
						src='https://www.mapmyrun.com/routes/view/embedded/6109976770?width=600&height=401&undefined&line_color=E61900DC&rgbhex=DC0019&distance_markers=1&unit_type=imperial&map_mode=ROADMAP&show_marker_every=1&last_updated=2024-06-23T07:09:42+00:00'
						height='401px' width='100%' />
					<div className="jss6325" id="embed-frame-footer">
						<p>
							<a href="https://www.mapmyrun.com/routes/view/6109976770" target="_blank" rel="noreferrer">View 5k at MapMyRun <OpenInNewIcon /></a>
						</p>

					</div>
				</Col>
				<Col xs="12" lg={6}>
					<iframe id='mapmyfitness_route' title="Santa&apos;s Run Fest 10k (2024)"
						src='https://www.mapmyrun.com/routes/view/embedded/6109972804?width=600&height=401&undefined&line_color=E61900DC&rgbhex=DC0019&distance_markers=1&unit_type=imperial&map_mode=ROADMAP&show_marker_every=1&last_updated=2024-06-23T07:09:42+00:00'
						height='401px' width='100%' />
					<div className="jss6325" id="embed-frame-footer">
						<p>
							<a href="https://www.mapmyrun.com/routes/view/6109972804" target="_blank" rel="noreferrer">View 10k at MapMyRun <OpenInNewIcon /></a>
						</p>

					</div>
				</Col>
			</Row>

		</PageWrapper>
	);
}

export default Course;


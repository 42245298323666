import React from 'react';
import './Sponsor.css';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';


type SponsorProps = {
    sponsorName: string;
    sponsorLink?: string;
    sponsorshipLevel?: SponsorshipLevel;
    logoSrc?: string;
    logoAltText?: string;
    children?: React.ReactNode;
    extraImagePadding?: boolean;
    imageBackgroundColor?: boolean;
    extraPadding?: boolean;
    backgroundColor?: boolean;
}

export enum SponsorshipLevel {
    Olympic,
    Elite,
    Champion,
    Marathon,
    Sprinter
}

function Sponsor(props: SponsorProps) {


    const noLogoSponsor =
        <>
            <div className="sponsorName">
                {props.sponsorName}
                {props.sponsorLink && <><OpenInNewIcon /></>}
            </div>
            <div>
                {props.children}
            </div>
        </>
        ;

    const noLogoSponsorWithLink =
        <>
            <a href={props.sponsorLink} target="_blank" rel="noreferrer">
                {noLogoSponsor}
            </a>
        </>
        ;

    const logoSponsor =
        <>
            <img className="sponsor-logo" src={props.logoSrc} alt={props.logoAltText} />
            {noLogoSponsor}
        </>
        ;

    const logoSponsorWithLink =
        <>
            <a href={props.sponsorLink} target="_blank" rel="noreferrer">
                {logoSponsor}
            </a>
        </>
        ;


    let classByLevel = "Sponsor";
    switch (props.sponsorshipLevel) {
        case SponsorshipLevel.Olympic:
            classByLevel = classByLevel.concat(" ", "olympic-sponsor");
            break;
        case SponsorshipLevel.Elite:
            classByLevel = classByLevel.concat(" ", "elite-sponsor");
            break;
        case SponsorshipLevel.Marathon:
            classByLevel = classByLevel.concat(" ", "marathon-sponsor");
            break;
        case SponsorshipLevel.Sprinter:
        default:
            classByLevel = classByLevel.concat(" ", "sprinter-sponsor");
    }
    if (props.extraImagePadding) {
        classByLevel = classByLevel.concat(" ", "extra-image-padding");
    }
    if (props.imageBackgroundColor) {
        classByLevel = classByLevel.concat(" ", "add-image-background-color");
    }
    if (props.extraPadding) {
        classByLevel = classByLevel.concat(" ", "extra-padding");
    }
    if (props.backgroundColor) {
        classByLevel = classByLevel.concat(" ", "add-background-color");
    }

    let contentStructure;
    if (props.logoSrc) {
        if (props.sponsorLink) {
            contentStructure = <>{logoSponsorWithLink}</>;
        } else {
            contentStructure = <>{logoSponsor}</>;
        }
    } else {
        if (props.sponsorLink) {
            contentStructure = <>{noLogoSponsorWithLink}</>;
        } else {
            contentStructure = <>{noLogoSponsor}</>;
        }
    }

    const content =
        <div className={classByLevel}>
            {contentStructure}
        </div>
        ;

    return content;
}

export default Sponsor;

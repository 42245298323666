import { useEffect } from "react";
import { canonicalUrlBase, pageTitleBase } from "../..";

type HeadManipulatorProps = {
	canonicalPath: string;
	pageName: string;
}

function HeadManipulator(props: HeadManipulatorProps) {

	useEffect(() => {
		// Sets the document title by appending page name to base title.
		if (props.pageName) {
			document.title = pageTitleBase + " - " + props.pageName;
		} else {
			document.title = pageTitleBase;
		}

		// Sets the page's canonical link by appending path prop to base url.
		const canonicalUrl = canonicalUrlBase + props.canonicalPath;
		const canonical: Element | null = document.head.querySelector("link[rel='canonical']");
		if (canonical) {
			canonical.setAttribute("href", canonicalUrl);
		} else {
			const c = document.createElement('link');
			c.setAttribute('rel', 'canonical');
			c.setAttribute('href', canonicalUrl);
			document.head.appendChild(c);
		}
	});

	return <template></template>;

}

export default HeadManipulator;